<template>
  <div>
    <b-row>
      <!-- Start Table -->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle class="w-100">
            <div class="d-flex justify-content-between !w-100">
              <h4 class="card-title">create Entry</h4>
            </div>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="addNewRecord">Add New Row</b-button>
          </template>
          <template v-slot:body>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="my-3" @submit.prevent="handleSubmit()">
                <b-row>
                  <b-col class="mb-2" lg="6">
                    <label>select Enterprice</label>
                    <select  class="form-control" id="reason" v-model="entryData.enterprise">
                      <option selected disabled value="">Choose Enterprice...</option>
                      <option v-for="(i, key) in createData.enterprise" :key="key" :value="i.id">
                        <span>{{i.title}}</span>
                      </option>
                    </select>
                  </b-col>
                  <b-col class="mb-2" lg="6">
                    <label>select project</label>
                    <select  class="form-control" id="reason" v-model="entryData.project">
                      <option selected disabled value="">Choose project...</option>
                      <option v-for="(i, key) in createData.projects" :key="key" :value="i.id">
                        <span>{{i.en_title}}</span>
                      </option>
                    </select>
                  </b-col>
                  <b-col class="mb-2" lg="6">
                    <label>Invoice</label>
                    <b-form-input v-model="entryData.invoice" type="text"
                                  placeholder="invoice"
                    ></b-form-input>
                  </b-col>
                  <b-col  class="mb-2" lg="6">
                    <label>Doc Date</label>
                    <flat-pickr class="form-control !bg-white" v-model="entryData.doc_date" placeholder="Select Date"></flat-pickr>
                  </b-col>
                  <b-col class="mb-2" lg="6">
                    <label>post Date</label>
                    <flat-pickr class="form-control !bg-white" v-model="entryData.post_date" placeholder="Select Date"></flat-pickr>
                  </b-col>
                  <b-col class="mb-2" lg="6">
                    <label>select classification</label>
                    <select  class="form-control" id="reason" v-model="entryData.classification">
                      <option selected disabled value="">Choose classification...</option>
                      <option v-for="(i, key) in createData.classification" :key="key" :value="i.id">
                        <span>{{i.title}}</span>
                      </option>
                    </select>
                  </b-col>
                </b-row>
              </form>
            </ValidationObserver>
            <b-table v-if="entryData.enterprise" striped responsive :fields="columns"  :items="allEntry"
                     class="mb-0 table-borderless"
                     sort-icon-left primary-key="name" id="my-table" :tbody-transition-props="transProps">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(category)="data">
                <select  class="form-control w-200" id="reason" v-model="allEntry[data.index].category">
                  <option selected disabled value="">Choose categories...</option>
                  <option v-for="(i, key) in createData.categories" :key="key" :value="i.id">
                    <span>{{i.title}}</span>
                  </option>
                </select>
              </template>
              <template #cell(code)="data">
                <p>{{allEntry[data.index].code}}</p>
              </template>
              <template #cell(amount)="data">
                <b-form-input class="w-200" v-model="allEntry[data.index].amount" type="text"
                              placeholder="amount"
                ></b-form-input>
              </template>
              <template #cell(currency)="data">
                <select  class="form-control w-200" id="currency" v-model="allEntry[data.index].currency">
                  <option selected disabled value="">Choose currency...</option>
                  <option v-for="(i, key) in createData.currencies" :key="key" :value="i.id">
                    <span>{{i.title}}</span>
                  </option>
                </select>
              </template>
              <template #cell(trans_type)="data">
                <div class="selectedParent d-flex flex-nowrap">
                        <span v-for="(transaction_type, key) in allTypes" :key="key" :class="[{ 'shadow selectedNumber' :
                        isSelected(data.index, transaction_type)},' w-50 gap_1 d-flex justify-content-center align-items-center bg-white  my-1']"
                              @click="allEntry[data.index].transaction_type = transaction_type">
                          {{transaction_type}}
                        </span>
                </div>
              </template>
              <template #cell(Acc_type)="data">
                <div class="selectedParent d-flex flex-nowrap">
                        <span v-for="(account_type, key) in allAccountTypes" :key="key" :class="[{ 'shadow selectedNumber' :
                        isSelectedAccountType(data.index, account_type)},' w-50 gap_1 d-flex justify-content-center align-items-center bg-white  my-1']"
                              @click="allEntry[data.index].account_type = account_type">
                          {{account_type}}
                        </span>
                </div>
              </template>
              <template #cell(payment_method)="data">
                <div class="selectedParent d-flex flex-nowrap">
                        <span v-for="(payment_method, key) in createData.payment_methods" :key="key" :class="[{ 'shadow selectedNumber' :
                        isSelectedPaymentMethod(data.index, payment_method)},' w-50 gap_1 d-flex justify-content-center align-items-center bg-white  my-1']"
                              @click="allEntry[data.index].payment_method = payment_method.id">
                          {{payment_method.title}}
                        </span>
                </div>
              </template>
              <template #cell(cheque)="data">
                <b-form-input class="w-200"  v-model="allEntry[data.index].cheque" type="text"
                              placeholder="cheque"
                ></b-form-input>
              </template>
              <template #cell(description)="data">
                <b-form-input class="w-200"  v-model="allEntry[data.index].description" type="text"
                              placeholder="description..."
                ></b-form-input>
              </template>
              <template #cell(department)="data">
                <select  class="form-control w-200" id="reason" v-model="allEntry[data.index].department">
                  <option selected disabled value="">Choose Department...</option>
                  <option v-for="(i, key) in selectedDeps.departments"
                          :key="key"
                          :value="i.id">
                    <span>{{i.title}}</span>
                  </option>
                </select>
              </template>
              <template v-slot:cell(action)="data">
                <b-button variant=" iq-bg-danger" size="sm">
                  {{ data.index }}
                  <i class="ri-bin-line m-0"></i></b-button>
              </template>
            </b-table>
            <div class="d-flex justify-content-end mt-4" v-if="allEntry.length >= 2">
              <b-button variant="primary" v-if="!loadnigEntry" @click="saveEntry">Save Entry</b-button>
              <b-button v-else variant="primary">
                <spinner-loading text="Saving" />
              </b-button>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- End Table -->
    </b-row>
  </div>
</template>
<script>
import settingService from '../../services/settings'
import { core } from '@/config/pluginInit'
export default {
  name: 'createEntry',
  data () {
    return {
      columns: [
        { label: 'Line Item', key: 'index', class: 'text-left min-width--200' },
        { label: 'Category', key: 'category', class: 'text-left min-width--200' },
        { label: 'Code', key: 'code', class: 'text-left min-width--200' },
        { label: 'Amount', key: 'amount', class: 'text-left min-width--200' },
        { label: 'Currency', key: 'currency', class: 'text-left' },
        { label: 'Trans. Type', key: 'trans_type', class: 'text-left' },
        { label: 'Acc. Type', key: 'Acc_type', class: 'text-left' },
        { label: 'Payment Method', key: 'payment_method', class: 'text-left' },
        { label: 'Cheque', key: 'cheque', class: 'text-left' },
        { label: 'Description', key: 'description', class: 'text-left' },
        { label: 'Department', key: 'department', class: 'text-left' },
        { label: 'Actions', key: 'action', class: 'text-left' }
      ],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      loadTable: false,
      allEntry: [],
      deleteId: '',
      createData: [],
      allTypes: [
        'Debit',
        'Credit'
      ],
      allAccountTypes: [
        'BS',
        'PL'
      ],
      selectedDeps: [],
      entryData: {
        enterprise: '',
        project: '',
        classification: '',
        invoice: '',
        post_date: '',
        doc_date: ''
      },
      loadnigEntry: false
    }
  },
  watch: {
    'entryData.enterprise' (value) {
      this.selectedDeps = this.createData.enterprise.find(data => data.id === value)
      this.allEntry = []
    }
  },
  methods: {
    saveEntry () {
      const credit = []
      const debit = []
      let totalCredit = 0
      let totalDebit = 0
      this.allEntry.map(data => {
        if (data.transaction_type === 'Credit') {
          totalCredit += Number(data.amount)
          credit.push(data)
        } else {
          totalDebit += Number(data.amount)
          debit.push(data)
        }
      })
      if (totalCredit === totalDebit) {
        this.loadnigEntry = true
        settingService.createEntries({ ...this.entryData, credit, debit }).then(res => {
          core.showSnackbar('success', res.data.message)
          this.$router.push({ name: 'entries' })
          this.loadnigEntry = false
        }).finally(() => {
          this.loadnigEntry = false
        })
      } else {
        core.showSnackbar('error', 'check your Entry')
      }
    },
    addNewRecord () {
      this.allEntry.push(
        {
          transaction_type: '',
          account_type: '',
          code: 0,
          department: '',
          category: '',
          payment_method: '',
          currency: '',
          amount: '',
          cheque: '',
          description: ''
        }
      )
    },
    isSelected (index, value) {
      return this.allEntry[index].transaction_type === value
    },
    isSelectedAccountType (index, value) {
      return this.allEntry[index].account_type === value
    },
    isSelectedPaymentMethod (index, value) {
      return this.allEntry[index].payment_method === value.id
    },
    getCreateData () {
      settingService.getALlDataToCreateEntries().then(res => {
        this.createData = res.data.data
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getCreateData()
  }
}
</script>
<style>
.selectedParent span {
  cursor: pointer;
  background: #ececec;
  padding: 5px 15px;
  border-radius: 10px;
  margin-right: 10px;
  color: #5b5b5b;
}
.selectedNumber{
  color: var(--iq-white) !important;
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
}
.w-200 {
  width: 200px !important;
}
</style>
